<template>
  <section id="print-declaration">
    <!-- card 1 -->
    <b-card
        header=""
        :title="$t('Module not enabled')"
    >
      <b-card-text>
        <p>
          {{ $t("The module is not enabled. Please contact us for enabling it") }}.
        </p>
      </b-card-text>


      <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="contact_us()"
          style="min-width: 135px"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"

      >
        <feather-icon
            icon="MailIcon"
            class="mr-50"
        />
        <span class="align-middle">{{ $t("Email us") }}</span>
      </b-button>
    </b-card>

  </section>
</template>

<script>
import {BRow, BCol, BCard,BCardText, BButton, BAvatar, BImg,} from 'bootstrap-vue'

import {getUserData} from '@/auth/utils'

import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import ability from "@/libs/acl/ability";
import {environment} from "@/auth/environment";
import ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BAvatar,
    BImg,
    ripple
  },
  methods: {
    refresh() {

      const userService = new UserService(useJwt)

      let company_id = this.userData.company.id


      userService.company(company_id).then(response => {
        this.admin_email = response.data.data.admin_email;
        console.log("admin_email",this.admin_email)
        this.company = response.data.data.company;

        this.company_modules = response.data.data.company_modules;


      })


    },

    contact_us() {

      const link = document.createElement("a");
      link.href = "mailto:"+this.admin_email+""
      link.click();
      URL.revokeObjectURL(link.href);

    },



  },
  data() {
    return {
      data: {},
      company_modules: {},
      company: {},
      admin_email: "",
      userData: JSON.parse(localStorage.getItem('userData')),
      is_admin: ability.can("manage", "admin"),
    }
  },
  created() {
    console.log('userData', this.userData)
    this.company = this.userData.company;
    this.refresh()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
